/**
 * Application Environment Configuration
 *
 * This constant represents the environment configuration for the application. It may include settings such
 * as API endpoints, feature flags, and other environment-specific parameters.
 *
 * @type {Object}
 */
export const environment = {
    production: false,
    api:'https://api.v2.tolima.travel',
    // api:'http://localhost:8000',
    token:'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJlbWFpbCI6IiIsImV4cCI6MTU0NDI5MjYwNiwib3JpZ19pYXQiOjE1NDA4MzY2MDYsInVzZXJfaWQiOjIsInVzZXJuYW1lIjoiYW5vbmltbyJ9.FW2GzE5BjVIVXUeq7ZtMb2HQQg_xjfXs-6UlXl_npZs',
    sentryMode: 'development',
    apiKeyMap:'AIzaSyCaKbVhcX_22R_pRKDYuNA7vox-PtGaDkI',
    apiKeyRoutesMap : 'AIzaSyBOiqaIXuqnO4aoDQaX-BPZKI-sCcgcePg',
    openAiToken:'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',
    baseUrl:'https://new.tolima.travel'
};
