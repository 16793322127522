import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { environment } from '@tt/environments/environment';
import { ErrorService } from '@tt/app/shared/services/error.service';
import { LocalStorageService } from '@tt/app/shared/services/local-storage.service';

/**
 * Interceptor for HTTP Requests
 *
 * This interceptor is used to intercept and modify HTTP requests before they are sent to the server.
 *
 * @export
 * @class DefaultInterceptor
 * @implements {HttpInterceptor}
 */
@Injectable()
export class DefaultInterceptor implements HttpInterceptor {

  /**
   * Creates an instance of DefaultInterceptor.
   * @memberof DefaultInterceptor
   */
  constructor(private errorService: ErrorService, private localStorageService: LocalStorageService) { }

  /**
   * Intercept HTTP Requests
   *
   * This method intercepts and potentially modifies HTTP requests before they are handled by the server.
   *
   * @param {HttpRequest<unknown>} request - The incoming HTTP request.
   * @param {HttpHandler} next - The next handler in the interceptor chain.
   * @return {Observable<HttpEvent<unknown>>} An Observable that emits HTTP events, including server responses or captured errors.
   */
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    /** @type {*} */
    const url = req.url;

    /** @type {*} */
    const rutasConToken = [
      'api/genders',
      'sign-up',
      '/api/maincompany/',
      '/api/itineraries/',
      '/api/restore-password',
      '/api/trip-planned/',
      '/api/companies/',
      '/api/trip-planned-photos/',
      '/api/trip-planned-comments/',
      '/api/trip-planned-questions/',
      '/api/company-photos/',
      '/api/company-questions/',
      '/api/company-comments/',
      '/api/touroperator-plans/',
      '/api/touroperator-comments/',
      '/api/touroperator-questions/',
      '/api/touroperator-programs/',
      '/api/quotations/',
    ];

    /** @type {*} */
    const rutasConAuthToken = [''];

    if (rutasConToken.some(ruta => url.includes(ruta))) {

      /** @type {*} */
      const token: string = environment.token;

      /** @type {*} */
      const headers = req.headers.set('Authorization', token);

      /** @type {*} */
      const request = req.clone({ headers });

      return next.handle(request).pipe(
        tap((event: HttpEvent<any>) => {
          this.errorService.hideErrorMessage();
          if (event instanceof HttpResponse) {
          }
        })
      );
    } else if (rutasConAuthToken.some(ruta => url.includes(ruta))) {

      /** @type {*} */
      const token: string = this.localStorageService.getItem('token');

      /** @type {*} */
      const headers = req.headers.set('Authorization', token);

      /** @type {*} */
      const request = req.clone({ headers });

      return next.handle(request).pipe(
        tap((event: HttpEvent<any>) => {
          this.errorService.hideErrorMessage();
          if (event instanceof HttpResponse) {
          }
        })
      );
    }


    else {
      this.errorService.hideErrorMessage();
      return next.handle(req);
    }
  }
}
